import { get, post, put, Delete } from "../axios";

// 视频分类-列表
export const videoClassifyList = (params) =>
  get("/web/video/classify/list", params);

// 视频分类-新增
export const videoClassifyAdd = (params) =>
  post("/web/video/classify/add", params);

// 视频分类-编辑
export const videoClassifyUpdate = (params) =>
  put("/web/video/classify/update", params);

// 视频分类-删除
export const videoClassifyDelete = (id) =>
  Delete("/web/video/classify/delete/" + id, "");
